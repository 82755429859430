import { useState, useEffect } from "react";

const useIsMobile = () => {
  // Initialize state as false
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    // Define the resize handler
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Set the initial value
    handleResize();

    // Add the event listener
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};

export default useIsMobile;
