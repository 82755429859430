"use client";

import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { BlogCard } from "../../shared/components/BlogCard";
import { Database } from "../../Supabase/supabase.types";
import useIsMobile from "../hooks/useIsMobile"; 

export type Blog = Database["public"]["Tables"]["blogs"]["Row"];

interface BlogListProps {
  blogs: Blog[];
  xs: number;
}

const BlogList: React.FC<BlogListProps> = React.memo(({ blogs = [], xs = 4 }) => {
  const isMobile = useIsMobile();

  const blogsDirection = isMobile ? "column" : "row";

  if(blogs.length === 0)
  {
    return (
      <Box alignSelf="center" mt={4}>
        <Typography color="black" variant="h4" mb={4}>
          Sorry, the blogs could not be found.
        </Typography>
      </Box>
    ); 
  }
  return (
    <Box width="100%">
      <Grid container spacing={2} direction={blogsDirection}>
        {blogs?.map((blog: Blog) => (
          <Grid item xs={xs} key={blog.id}>
            <BlogCard blog={blog} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
});

BlogList.displayName = 'BlogList';
export default BlogList;
